import React, { useEffect } from "react";
import './App.css';
import NavBar from "./components/NavBar";
import { useAuth0 } from "./react-auth0-spa";
import { Router, Route, Switch } from "react-router-dom";
import Profile from "./components/Profile";
import PrivateRoute from "./components/PrivateRoute";
import history from "./utils/history";

function App() {
    useEffect(() => {
        fetch('https://odvz5leus8.execute-api.us-east-1.amazonaws.com/dev/notes')
            .then(rsp => rsp.json())
            .then(data => console.log(data))
            .catch(e => console.log(e))
    }, []);

    const { loading } = useAuth0();

    if (loading) {
        return <div>Loading...</div>;
    }
    return (
        <div className="App">
            <Router history={history}>
                <header>
                    <NavBar />
                </header>
                <Switch>
                    <Route path="/" exact />
                    <PrivateRoute path="/profile" component={Profile} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;